<template>
    <section v-if="loaded" class="inner-banner dev-details">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <div class="inner-banner-content">
                        <p>{{ candidate.profile.first_name }} {{ candidate.profile.last_name }}</p>
                    </div>
                </div>
                <div class="cover-photo-wrapper position-relative">
                    <div class="position-relative" style="z-index: 2;">
                        <div class="developer-author">
                            <div class="edit edit-profile-picture">
                                <input type="file"
                                       ref="file"
                                       style="display: none"
                                       accept="image/png,image/jpg,image/jpeg"
                                       @change="uploadImage"
                                >
                                <button @click="$refs.file.click()" class="btn-sm">edit</button>
                            </div>
                            <img class="img-fluid"
                                 :src="candidate.profile.image || 'https://cdn.zutzu.com/images/no-image/no-image.jpg'"
                                 style="height: 14rem; width: 18rem; object-fit: cover;"
                            >
                            <div v-if="errors[`image`]" v-for="error in errors[`image`]" :key="error" class="text-danger">
                                {{error }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="row dev-profile-details-wrap">
                        <div class="col-md-7 col-lg-7">
                            <div class="inner-banner-content">
                                <div class="developer-profile">
                                    <div class="developer-meta">
                                        <p>{{ candidate.profile.first_name }} {{ candidate.profile.last_name }}</p>
                                        <p>({{ candidate.profile.phone_number }}) {{ candidate.email }}</p>
                                        <div class="position-relative">
                                            <input class="form-control" v-model="candidate.about.job_title" placeholder="Job title">
                                            <div v-if="errors[`about.job_title`]" v-for="(error, index) in errors[`about.job_title`]" :key="index" class="text-danger">
                                                {{error}}
                                            </div>
                                        </div>
                                        <div class="dev-rating">
                                            <div class="position-relative w-100">
                                                <Multiselect
                                                    v-model="candidate.about.industry_id"
                                                    :searchable="true"
                                                    :options="industries"
                                                    :placeholder="$t('Select industry')"
                                                    class="form-control"
                                                />
                                                <div v-if="errors[`about.industry_id`]" v-for="(error, index) in errors[`about.industry_id`]" :key="index" class="text-danger">
                                                    {{error}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section v-if="loaded" class="inner-page-content-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 order-lg-1">
                    <div class="sidebar">
                        <div class="location-details">
                            <div class="locations">
                                <ul>
                                    <li class="d-flex justify-content-between">
                                        <div><i class="fas fa-map-marker-alt"></i>Atrašanās vieta:<br>
                                            <Multiselect
                                                v-model="candidate.profile.country_id"
                                                :searchable="true"
                                                :options="countries"
                                                :placeholder="$t('Select country')"
                                                class="form-control"
                                            />
                                            <div v-if="errors[`profile.country_id`]" v-for="(error, index) in errors[`profile.country_id`]" :key="index" class="text-danger">
                                                {{error}}
                                            </div>
                                        </div>
                                    </li>
                                    <li><i class="fas fa-dollar-sign"></i>Alga:<button v-if="!candidate.about.expected_salary" @click="candidate.about.expected_salary={}" class="btn-sm">+</button>
                                        <template v-if="candidate.about.expected_salary">
                                            <select class="custom-select form-control" v-model="candidate.about.expected_salary.period">
                                                <option v-for="period in employmentPeriods" :value="period.name">
                                                    {{ period.name }}
                                                </option>
                                            </select>
                                            <input v-model="candidate.about.expected_salary.amount_from"
                                                   type="number" step="0.01"
                                                   class="form-control">
                                            <input v-model="candidate.about.expected_salary.amount_to"
                                                   type="number" step="0.01"
                                                   class="form-control">
                                            <select class="custom-select form-control"
                                                    v-model="candidate.about.expected_salary.currency">
                                                <option value="EUR">EUR</option>
                                                <option value="RUB">RUB</option>
                                            </select>
                                            <select class="custom-select form-control"
                                                    v-model="candidate.about.expected_salary.type">
                                                <option value="Net">Net</option>
                                                <option value="Gross">Gross</option>
                                            </select>
                                        </template>
                                        <div v-if="errors[`about.expected_salary.employment`]" v-for="(error, index) in errors[`about.expected_salary.employment`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <div v-if="errors[`about.expected_salary.amount_from`]" v-for="(error, index) in errors[`about.expected_salary.amount_from`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <div v-if="errors[`about.expected_salary.amount_to`]" v-for="(error, index) in errors[`about.expected_salary.amount_to`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <div v-if="errors[`about.expected_salary.currency`]" v-for="(error, index) in errors[`about.expected_salary.currency`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <div v-if="errors[`about.expected_salary.type`]" v-for="(error, index) in errors[`about.expected_salary.type`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                    </li>
                                    <li><i class="far fa-eye"></i>Pēdējo reizi redzēts: <br>
                                        <span>{{ candidate.last_login ? moment(candidate.last_login).fromNow() : 'N/A' }}</span></li>
                                    <li class="d-flex justify-content-between flex-column edit-item-inner-block edit-item-inner-block">
                                        <div class="d-flex justify-content-between">
                                            <div><i class="fas fa-brain"></i>Prasmes:<button @click="candidate.skills.push('')" class="btn-sm">+</button>
                                            </div>
                                        </div>
                                        <div class="skills">
                                            <div class="text-center">
                                                <div v-for="(skill, index) in candidate.skills" :key="index">
                                                    <input v-model="candidate.skills[index]"><button @click="candidate.skills.splice(index, 1)" class="btn-sm">-</button>
                                                    <div v-if="errors[`skills.${index}`]" v-for="(error, index) in errors[`skills.${index}`]" :key="index" class="text-danger">
                                                        {{error}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-9 order-lg-2">
                    <div class="inner-main-content-wrap">
                        <div class="inner-main-content">
                            <div class="position-relative edit-item-inner-block">
                                <div class="inner-about">
                                    <div class="d-flex">
                                        <p class="title2 flex-grow-1">Par mani
                                            <button v-if="!candidate.about.description" @click="candidate.about.description=' '" class="btn-sm">+</button>
                                        </p>
                                    </div>
                                    <textarea v-if="candidate.about.description" class="form-control"
                                              v-model="candidate.about.description" rows="2"
                                    />
                                    <div v-if="errors[`about.description`]" v-for="(error, index) in errors[`about.description`]" :key="index" class="text-danger">
                                        {{error}}
                                    </div>
                                </div>
                            </div>
                            <div class="position-relative edit-item-inner-block">
                                <div class="experience-part keyword-edit-container">
                                    <div class="d-flex"><p class="title2 flex-grow-1">Pieredze<button @click="candidate.experiences.push({})" class="btn-sm">+</button></p></div>
                                    <div v-for="(exp, index) in candidate.experiences" :key="index">
                                        <input type="text" v-model="candidate.experiences[index].job_title" class="form-control" placeholder="Job Title">
                                        <div v-if="errors[`experiences.${index}.job_title`]" v-for="(error, index) in errors[`experiences.${index}.job_title`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <input type="text" v-model="candidate.experiences[index].employer" class="form-control" placeholder="Employer">
                                        <div v-if="errors[`experiences.${index}.employer`]" v-for="(error, index) in errors[`experiences.${index}.employer`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <Multiselect
                                            v-model="candidate.experiences[index].industry_id"
                                            :searchable="true"
                                            :options="industries"
                                            :placeholder="$t('Select industry')"
                                            class="form-control"
                                        />
                                        <div v-if="errors[`experiences.${index}.industry_id`]" v-for="(error, index) in errors[`experiences.${index}.industry_id`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <datepicker class="form-control"
                                                    :value="candidate.experiences[index].date_from"
                                                    @update:modelValue="candidate.experiences[index].date_from = moment($event).format('yyyy-MM-DD')"
                                                    :placeholder="candidate.experiences[index].date_from || 'Date from'"
                                        />
                                        <div v-if="errors[`experiences.${index}.date_from`]" v-for="(error, index) in errors[`experiences.${index}.date_from`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <datepicker class="form-control"
                                                    :value="candidate.experiences[index].date_to"
                                                    @update:modelValue="candidate.experiences[index].date_to = moment($event).format('yyyy-MM-DD')"
                                                    :placeholder="candidate.experiences[index].date_to || 'Date to'"
                                        />
                                        <div v-if="errors[`experiences.${index}.date_to`]" v-for="(error, index) in errors[`experiences.${index}.date_to`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <input v-model="candidate.experiences[index].is_current" type="checkbox"> <label>Is Current</label>
                                        <div v-if="errors[`experiences.${index}.is_current`]" v-for="(error, index) in errors[`experiences.${index}.is_current`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <textarea class="form-control" v-model="candidate.experiences[index].description" placeholder="Description" rows="2" />
                                        <div v-if="errors[`experiences.${index}.description`]" v-for="(error, index) in errors[`experiences.${index}.description`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <Multiselect
                                            v-model="candidate.experiences[index].country_id"
                                            :searchable="true"
                                            :options="countries"
                                            :placeholder="$t('Select country')"
                                            class="form-control"
                                        />
                                        <div v-if="errors[`experiences.${index}.country_id`]" v-for="(error, index) in errors[`experiences.${index}.country_id`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <button @click="candidate.experiences.splice(index, 1)" class="btn-sm">-</button>
                                    </div>
                                </div>
                            </div>
                            <div class="position-relative edit-item-inner-block">
                                <div class="experience-part keyword-edit-container">
                                    <div class="d-flex"><p class="title2 flex-grow-1">Izglītība<button @click="candidate.educations.push({})" class="btn-sm">+</button></p></div>
                                    <div v-for="(edu, index) in candidate.educations" :key="index">
                                        <input v-model="candidate.educations[index].is_current" type="checkbox"> <label>Is Current</label>
                                        <div v-if="errors[`educations.${index}.is_current`]" v-for="(error, index) in errors[`educations.${index}.is_current`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <input type="text" v-model="candidate.educations[index].institution" class="form-control" placeholder="Institution">
                                        <div v-if="errors[`educations.${index}.institution`]" v-for="(error, index) in errors[`educations.${index}.institution`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <input type="text" v-model="candidate.educations[index].field" class="form-control" placeholder="Field">
                                        <div v-if="errors[`educations.${index}.field`]" v-for="(error, index) in errors[`educations.${index}.field`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <input type="number" v-model="candidate.educations[index].year_from" class="form-control" placeholder="Year from">
                                        <div v-if="errors[`educations.${index}.year_from`]" v-for="(error, index) in errors[`educations.${index}.year_from`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <input type="number" v-model="candidate.educations[index].year_to" class="form-control" placeholder="Year to">
                                        <div v-if="errors[`educations.${index}.year_to`]" v-for="(error, index) in errors[`educations.${index}.year_to`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <textarea class="form-control" v-model="candidate.educations[index].description" placeholder="Description" rows="2" />
                                        <div v-if="errors[`educations.${index}.description`]" v-for="(error, index) in errors[`educations.${index}.description`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <button @click="candidate.educations.splice(index, 1)" class="btn-sm">-</button>
                                    </div>
                                </div>
                            </div>
                            <div class="position-relative edit-item-inner-block">
                                <div class="experience-part keyword-edit-container">
                                    <div class="d-flex"><p class="title2 flex-grow-1">Valodas<button @click="candidate.languages.push({})" class="btn-sm">+</button></p></div>
                                    <div v-for="(lang, index) in candidate.languages" :key="index">
                                        <Multiselect
                                            v-model="candidate.languages[index].id"
                                            :searchable="true"
                                            :options="languages"
                                            :placeholder="$t('Select language')"
                                            class="form-control"
                                        />
                                        <div v-if="errors[`languages.${index}.id`]" v-for="(error, index) in errors[`languages.${index}.id`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <Multiselect
                                            v-model="candidate.languages[index].level"
                                            :options="levels"
                                            :placeholder="$t('Select level')"
                                            class="form-control"
                                        />
                                        <div v-if="errors[`languages.${index}.level`]" v-for="(error, index) in errors[`languages.${index}.level`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <button @click="candidate.languages.splice(index, 1)" class="btn-sm">-</button>
                                    </div>
                                </div>
                            </div>
                            <div class="position-relative edit-item-inner-block">
                                <div class="experience-part keyword-edit-container">
                                    <div class="d-flex"><p class="title2 flex-grow-1">Kursi<button @click="candidate.courses.push({})" class="btn-sm">+</button></p></div>
                                    <div v-for="(course, index) in candidate.courses" :key="index">
                                        <input type="text" v-model="candidate.courses[index].name" class="form-control" placeholder="Name">
                                        <div v-if="errors[`courses.${index}.name`]" v-for="(error, index) in errors[`courses.${index}.name`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <input type="number" v-model="candidate.courses[index].year" class="form-control" placeholder="Year">
                                        <div v-if="errors[`courses.${index}.year`]" v-for="(error, index) in errors[`courses.${index}.year`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <textarea class="form-control" v-model="candidate.courses[index].description" placeholder="Description" rows="2" />
                                        <div v-if="errors[`courses.${index}.description`]" v-for="(error, index) in errors[`courses.${index}.description`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <button @click="candidate.courses.splice(index, 1)" class="btn-sm">-</button>
                                    </div>
                                </div>
                            </div>
                            <div class="position-relative edit-item-inner-block">
                                <div class="experience-part keyword-edit-container">
                                    <div class="d-flex"><p class="title2 flex-grow-1">Prakse<button @click="candidate.internships.push({})" class="btn-sm">+</button></p></div>
                                    <div v-for="(int, index) in candidate.internships" :key="index">
                                        <input type="text" v-model="candidate.internships[index].job_title" class="form-control" placeholder="Job Title">
                                        <div v-if="errors[`internships.${index}.job_title`]" v-for="(error, index) in errors[`internships.${index}.job_title`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <input type="text" v-model="candidate.internships[index].employer" class="form-control" placeholder="Employer">
                                        <div v-if="errors[`internships.${index}.employer`]" v-for="(error, index) in errors[`internships.${index}.employer`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <Multiselect
                                            v-model="candidate.internships[index].industry_id"
                                            :searchable="true"
                                            :options="industries"
                                            :placeholder="$t('Select industry')"
                                            class="form-control"
                                        />
                                        <div v-if="errors[`internships.${index}.industry_id`]" v-for="(error, index) in errors[`internships.${index}.industry_id`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <datepicker class="form-control"
                                                    :value="candidate.internships[index].date_from"
                                                    @update:modelValue="candidate.internships[index].date_from = moment($event).format('yyyy-MM-DD')"
                                                    :placeholder="candidate.internships[index].date_from || 'Date from'"
                                        />
                                        <div v-if="errors[`internships.${index}.date_from`]" v-for="(error, index) in errors[`internships.${index}.date_from`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <datepicker class="form-control"
                                                    :value="candidate.internships[index].date_to"
                                                    @update:modelValue="candidate.internships[index].date_to = moment($event).format('yyyy-MM-DD')"
                                                    :placeholder="candidate.internships[index].date_to || 'Date to'"
                                        />
                                        <div v-if="errors[`internships.${index}.date_to`]" v-for="(error, index) in errors[`internships.${index}.date_to`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <input v-model="candidate.internships[index].is_current" type="checkbox"> <label>Is Current</label>
                                        <div v-if="errors[`internships.${index}.is_current`]" v-for="(error, index) in errors[`internships.${index}.is_current`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <textarea class="form-control" v-model="candidate.internships[index].description" placeholder="Description" rows="2" />
                                        <div v-if="errors[`internships.${index}.description`]" v-for="(error, index) in errors[`internships.${index}.description`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <Multiselect
                                            v-model="candidate.internships[index].country_id"
                                            :searchable="true"
                                            :options="countries"
                                            :placeholder="$t('Select country')"
                                            class="form-control"
                                        />
                                        <div v-if="errors[`internships.${index}.country_id`]" v-for="(error, index) in errors[`internships.${index}.country_id`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <button @click="candidate.internships.splice(index, 1)" class="btn-sm">-</button>
                                    </div>
                                </div>
                            </div>
                            <div class="position-relative edit-item-inner-block">
                                <div class="courses-part keyword-edit-container">
                                    <div class="d-flex"><p class="title2 flex-grow-1">Vaļasprieki<button @click="candidate.hobbies.push('')" class="btn-sm">+</button></p></div>
                                    <div v-for="(hobby, index) in candidate.hobbies" :key="index">
                                        <input type="text" v-model="candidate.hobbies[index]" placeholder="Enter Hobby">
                                        <div v-if="errors[`hobbies.${index}`]" v-for="(error, index) in errors[`hobbies.${index}`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <button @click="candidate.hobbies.splice(index, 1)" class="btn-sm">-</button>
                                    </div>
                                </div>
                            </div>
                            <div class="position-relative edit-item-inner-block">
                                <div class="experience-part">
                                    <div class="d-flex"><p class="title2 flex-grow-1">Sertifikāti<button @click="candidate.certificates.push({})" class="btn-sm">+</button></p></div>
                                    <div v-for="(cert, index) in candidate.certificates" :key="index">
                                        <input type="text" v-model="candidate.certificates[index].name" class="form-control" placeholder="Name">
                                        <div v-if="errors[`certificates.${index}.name`]" v-for="(error, index) in errors[`certificates.${index}.name`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <datepicker class="form-control"
                                                    :value="candidate.certificates[index].date_from"
                                                    @update:modelValue="candidate.certificates[index].date_from = moment($event).format('yyyy-MM-DD')"
                                                    :placeholder="candidate.certificates[index].date_from || 'Date from'"
                                        />
                                        <div v-if="errors[`certificates.${index}.date_from`]" v-for="(error, index) in errors[`certificates.${index}.date_from`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <datepicker class="form-control"
                                                    :value="candidate.certificates[index].date_to"
                                                    @update:modelValue="candidate.certificates[index].date_to = moment($event).format('yyyy-MM-DD')"
                                                    :placeholder="candidate.certificates[index].date_to || 'Date to'"
                                        />
                                        <div v-if="errors[`certificates.${index}.date_to`]" v-for="(error, index) in errors[`certificates.${index}.date_to`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <textarea class="form-control" v-model="candidate.certificates[index].description" placeholder="Description" rows="2" />
                                        <div v-if="errors[`certificates.${index}.description`]" v-for="(error, index) in errors[`certificates.${index}.description`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <button @click="candidate.certificates.splice(index, 1)" class="btn-sm">-</button>
                                    </div>
                                </div>
                            </div>
                            <div class="position-relative edit-item-inner-block">
                                <div class="courses-part keyword-edit-container">
                                    <div class="d-flex"><p class="title2 flex-grow-1">Galvenie sasniegumi<button @click="candidate.achievements.push('')" class="btn-sm">+</button></p></div>
                                    <div v-for="(achievement, index) in candidate.achievements" :key="index">
                                        <input type="text" v-model="candidate.achievements[index]" class="" placeholder="Enter Achievement">
                                        <div v-if="errors[`achievements.${index}`]" v-for="(error, index) in errors[`achievements.${index}`]" :key="index" class="text-danger">
                                            {{error}}
                                        </div>
                                        <button @click="candidate.achievements.splice(index, 1)" class="btn-sm">-</button>
                                    </div>
                                </div>
                            </div>
                            <div class="row bottom-btn-part">
                                <div class="col-lg-12">
                                    <div class="bottom-btn-inner">
                                        <div class="row align-items-center">
                                            <div class="col-xl-5 col-lg-4">
                                                <div class="bottom-left-btns">
                                                    <button class="btn btn-primary ml-2"><span><i
                                                        class="fas fa-print mr-1"></i>Izdruka</span></button>
                                                    <button v-if="canEdit" @click="updateCandidate" class="btn btn-success float-end m-3">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import moment from "moment";
import {myCandidateService} from "@/services/myCandidateService";
import Datepicker from 'vue3-datepicker'
import languageLevels from "@/js/languageLevels";
import Multiselect from '@vueform/multiselect'

export default {
    name: "EditCandidate",
    data() {
        return {
            candidate: {},
            moment: moment,
            levels: languageLevels,
            canEdit: false,
            loaded: false,
        }
    },
    computed: {
        candidateId() {
            return this.$route.params.id
        },
        industries() {
            return this.$store.getters.industries
        },
        employmentPeriods() {
            return this.$store.getters.employmentPeriods
        },
        languages() {
            return this.$store.getters.languages
        },
        countries() {
            return this.$store.getters.countries
        }
    },
    created() {
        this.getCandidate()
    },
    methods: {
        getCandidate() {
            myCandidateService.getCandidate(this.candidateId)
                .then(response => {
                    if (! response.data.canEdit) {
                        this.$router.push({ name: 'Home' })
                    }
                    this.candidate = response.data.data
                    if (! this.candidate.about) {
                        this.candidate.about = {}
                    }
                    this.canEdit = response.data.canEdit
                    this.loaded = true
                })
                .catch(error => {
                    this.errors = error.response.data.errors || []
                })
        },
        updateCandidate() {
            myCandidateService.updateCandidate(this.candidateId, this.candidate)
                .then(response => {
                    this.errors = []
                    alert('Update successful')
                })
                .catch(error => {
                    this.errors = error.response.data.errors || []
                })
        },
        uploadImage(event) {
            const formData = new FormData();

            formData.append('image', event.target.files[0])

            myCandidateService.updateProfileImage(this.candidateId, formData)
                .then(response => {
                    this.errors = []
                    this.candidate.profile.image = response.data.data
                })
                .catch(error => {
                    this.errors = error.response.data.errors || []
                })
        }
    },
    components: {
        Datepicker,
        Multiselect
    }
}
</script>